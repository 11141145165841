import { firebaseApp } from './Firebase'
import 'firebase/remote-config'

export enum ConfigValue {
  appConfigHost = 'app_config_host',
  deepLinkDomain = 'deep_link_domain',
  deepLinkItem = 'deep_link_item',
  shareAndroidPackage = 'share_android_package',
  shareIOSId = 'share_ios_id',
  shareIOSPackage = 'share_ios_package',
  shopifyApiBaseUrl = 'shopify_api_base_url',
  shopifyApiToken = 'shopify_api_token',
  defaultDynamicLink = 'default_dynamic_link'
}

export const remoteConfig = firebaseApp.remoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = 30000 // 3600000

// staging:
// remoteConfig.defaultConfig = {
//   app_config_host: 'https://staging.motivationalquotes.me',
//   deep_link_domain: 'https://share.motivationalquotes.me',
//   deep_link_item: 'https://motivationalquotes.me/detail/{itemId}',
//   share_android_package: 'me.motivationalquotes',
//   share_ios_id: '6480112282',
//   share_ios_package: 'me.motivationalquotes',
//   shopify_api_base_url: 'https://spruche-app-de.myshopify.com',
//   shopify_api_token: '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',
//   default_dynamic_link: 'https://share.staging.motivationalquotes.me'
// }
//
// live:
remoteConfig.defaultConfig = {
  app_config_host: 'https://live.motivationalquotes.me',
  deep_link_domain: 'https://share.motivationalquotes.me',
  deep_link_item: 'https://motivationalquotes.me/detail/{itemId}',
  share_android_package: 'me.motivationalquotes',
  share_ios_id: '6480112282',
  share_ios_package: 'me.motivationalquotes',
  shopify_api_base_url: 'https://spruche-app-de.myshopify.com',
  shopify_api_token: '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',
  default_dynamic_link: 'https://share.motivationalquotes.me'
}

remoteConfig.fetchAndActivate()
