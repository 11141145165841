import firebase from 'firebase/app'
import 'firebase/analytics'

export const dynamicLinkAPIKey = 'AIzaSyBpJV8_nFIUUBRdo_u_os8TjntYM0FDY1w'

// staging
// const firebaseConfig = {
//   apiKey: 'AIzaSyDx-iUGduFZBPYgsmPnwEC5vexn7qPAECQ',
//   authDomain: 'motivationalquotes-staging.firebaseapp.com',
//   databaseURL: 'https://motivationalquotes-staging-default-rtdb.firebaseio.com',
//   projectId: 'motivationalquotes-staging',
//   storageBucket: 'motivationalquotes-staging.firebasestorage.app',
//   messagingSenderId: '1092295949144',
//   appId: '1:1092295949144:web:c4804c5531a1ec4ed9c32d',
//   measurementId: 'G-3ZKZE5TZD3'
// }

// Live:
const firebaseConfig = {
  apiKey: 'AIzaSyBpJV8_nFIUUBRdo_u_os8TjntYM0FDY1w',
  authDomain: 'motivationalquotes-live.firebaseapp.com',
  databaseURL: 'https://motivationalquotes-live-default-rtdb.firebaseio.com',
  projectId: 'motivationalquotes-live',
  storageBucket: 'motivationalquotes-live.firebasestorage.app',
  messagingSenderId: '147049902791',
  appId: '1:147049902791:web:6baf1546dafa9b58ac44e2',
  measurementId: 'G-XD5G91C2KY'
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

export function enableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(true)
}

export function disableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(false)
}
